

#select{
  border-color: #03DAC5;
  background-color: #fff;
  width: 98%;
  color: #2B2B3E;
  text-align: center;
  min-height: 72px;
  box-shadow: '0 0 0 0.2rem #03DAC5';
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  font-size: x-large;
  font-weight: bold;
}

ul{
  color: #9576B8;
  background-color: #fff;
  justify-content: center;
}

li{
  color: #2B2B3E;
  background-color: #fff;
}

li[aria-selected="true"]{
  background-color: #9576B8;
}

#stock{
  color: #2B2B3E;
}

.recharts-layer .recharts-cartesian-axis-tick{
  color: #9576B8;

}


.recharts-layer {
  color: #9576B8;

}

.recharts-cartesian-axis-tic {
  color: #9576B8;

}
