//
// Tabs
//

// Tabs
.nav-pills,
.nav-tabs {
  margin: 0 0 25px 0;

  .nav-item {
    .nav-link {
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
      font-weight: 400;
      @include kt-transition();
      @include kt-icons-style(
        font-size,
        (
          fontawesome: 1.2rem,
          lineawesome: 1.3rem,
          flaticon: 1.4rem
        )
      );
      font-size: 1rem;
      font-weight: 400;

      i {
        vertical-align: middle;
        line-height: 0;
        display: inline-block;
        margin-right: 0.5rem;
      }

      &.active,
      &:active,
      &:hover {
        @include kt-transition();
      }
    }
  }
}

// Default Tabs
.nav-tabs {
  .nav-item {
    .nav-link {
      color: kt-brand-color() ;

      i {
        color: kt-brand-color() ;
      }

      &.active,
      &:active,
      &:hover {
        color: kt-brand-color() ;

        i {
          color: kt-brand-color() ;
        }
      }

      &.disabled {
        color: kt-base-color(label, 2);

        i {
          color: kt-base-color(label, 2);
        }
      }
    }

    &.show > .nav-link {
      color: kt-brand-color() ;

      i {
        color: kt-brand-color() ;
      }
    }
  }
}

// Pill Tabs
.nav-pills {
  .nav-item {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    .nav-link {
      color: kt-base-color(label, 3);

      &:active,
      &.active,
      &.active:hover {
        background-color: kt-brand-color() ;
        color: kt-brand-color(inverse);
      }
    }

    &.show > .nav-link {
      background-color: kt-brand-color() ;
      color: kt-brand-color(inverse);
    }
  }

  // Sizing
  &.nav-pills-sm {
    .nav-item {
      .nav-link {
        font-size: 0.9rem;
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
      }
    }
  }

  // Bold
  &.nav-pills-bold {
    .nav-item {
      .nav-link {
        font-weight: 500;
      }
    }
  }

  // Label style
  &.nav-pills-label {
    .nav-item {
      .nav-link {
        &:active,
        &.active,
        &.active:hover {
          background-color: rgba(kt-brand-color() , 0.1);
          color: kt-brand-color() ;
        }
      }

      &.show > .nav-link {
        background-color: rgba(kt-brand-color() , 0.1);
        color: kt-brand-color() ;
      }
    }
  }
}

// Marginless
.nav-fit {
  margin: 0 !important;
}
