//
// DataTables
//

// Base
@mixin kt-datatables-base() {
  // Pagination colors
  $page-size: 2.25rem;

  // Base wrapper
  .dataTables_wrapper {
    padding: 0 !important;

    // Table
    .dataTable {
      width: 100% !important;
      border-collapse: initial !important;
      border-spacing: 0 !important;
      margin: 1rem 0 !important;

      // General
      th,
      td {
        vertical-align: middle;

        // Row checkbox
        .kt-checkbox {
          margin-top: 0.3rem;
          margin-bottom: 0;
        }

        // Cell alignments
        &.dt-center {
          text-align: center;
        }

        &.dt-left {
          text-align: left;
        }

        &.dt-right {
          text-align: right;
        }

        // Sorting
        &.sorting_desc,
        &.orting_asc_disabled,
        &.orting_desc_disabled,
        &.sorting_asc,
        &.sorting {
          &:before,
          &:after {
            bottom: 1.25rem !important;
          }
        }
      }

      // Filter
      .filter {
        th,
        td {
          vertical-align: top;

          .input-group + .input-group {
            margin-top: 0.5rem;
          }

          .btn + .btn {
            margin-top: 0.5rem;
          }
        }
      }

      // Group
      .group {
        th,
        td {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }

    // Scrollable
    .dataTables_scroll {
      margin: 1rem 0 !important;

      .dataTable {
        margin: 0 !important;
      }
    }

    // Pagination
    .dataTables_paginate {
      .pagination {
        .page-item {
          margin-left: 0.4rem;
          > .page-link {
            border-radius: 3px;

            @include kt-not-rounded {
              border-radius: 0;
            }

            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: $page-size;
            min-width: $page-size;
            vertical-align: middle;
            padding: 0.5rem;
            text-align: center;
            position: relative;

            font-size: 1rem;
            line-height: 1rem;
            font-weight: 400;

            > i {
              font-size: 1rem;
              text-align: center;
              display: inline-block;
            }
          }
        }
      }
    }

    // Filter
    .text-right,
    .text-left {
      .dataTables_filter {
        display: inline-block;
      }
    }

    // Info
    .dataTables_info {
      padding-top: 0.45rem;
    }

    // Custom pager
    .dataTables_pager {
      text-align: right;

      // Length
      .dataTables_length {
        margin-right: 0.5rem;
        display: inline-block;

        & + .dataTables_paginate {
          margin-left: 1rem;
        }
      }

      // Info
      .dataTables_info {
        display: inline-block;
        margin-right: 0.5rem;
      }

      // Pagination
      .dataTables_paginate {
        display: inline-block;
      }
    }

    @include kt-tablet-and-mobile {
      .dataTables_pager {
        margin-top: 1rem;
        text-align: center;
      }
    }

    @include kt-mobile {
      .dataTables_paginate {
        margin-left: 0 !important;

        .pagination .page-item:first-child {
          margin-left: 0 !important;
        }
      }

      .dataTables_paginate {
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    // Responsive mode toggle icon
    table.dataTable.dtr-inline.collapsed > tbody > {
      tr[role="row"] > td:first-child:before {
        box-shadow: none;
        border-radius: 0;
        top: 50%;
        left: 8px;
        height: 18px;
        width: 18px;
        margin-top: -9px;
        font-size: 18px;
        color: kt-state-color(brand, base);
        border: 0;
        background-color: transparent;

        @include kt-la-icon-self("\f2c3");
      }

      tr.parent > td:first-child:before {
        @include kt-la-icon-self("\f28f");
        color: kt-state-color(brand, base);
        background-color: transparent;
      }
    }

    // Responsive mode row expand details
    .child {
      .dtr-details {
        display: table !important;

        > li {
          display: table-row !important;
          border: 0 !important;

          .dtr-title {
            display: table-cell;
            vertical-align: top;
            border-bottom: 1px dashed #efefef;
            font-weight: 500;
            padding: 0.5rem 2rem 0.5rem 0;

            &:after {
              content: ":";
            }
          }

          .dtr-data {
            display: table-cell;
            vertical-align: top;
            border-bottom: 1px dashed #efefef;
            padding: 0.5rem 0;
          }

          &:last-child {
            .dtr-title {
              border-bottom: 0;
            }

            .dtr-data {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  // Bootstrap 4
  .dt-bootstrap4 {
    .dropdown {
      .dropdown-menu.dropdown-menu-right {
        /*rtl:ignore*/
        margin-left: 65px;
      }
    }
  }
}

@mixin kt-datatables-skin() {
  // Pagination colors
  $base_color: #f4f3f8;
  $page-default: darken($base-color, 3%);
  $page-default-font: kt-base-color(label, 3);
  $page-hover: kt-state-color(brand, base);
  $page-hover-font: kt-state-color(brand, inverse);
  $page-active: kt-state-color(brand, base);
  $page-active-font: kt-state-color(brand, inverse);

  // Base wrapper
  .dataTables_wrapper {
    // Table
    .dataTable {
      // General
      th,
      td {
        color: kt-base-color(label, 3);
      }

      // Row selected
      .selected {
        th,
        td {
          background-color: kt-base-color(grey, 1);
          color: kt-base-color(label, 3);
        }
      }

      // Group
      .group {
        th,
        td {
          background-color: kt-base-color(grey, 1);
        }
      }
    }

    // Pagination
    .pagination {
      .page-item {
        > .page-link {
          color: $page-default-font;
          border: 0;
          outline: none !important;
        }

        &.previous,
        &.next,
        &.last,
        &.first {
          > .page-link {
            background: $page-default;

            &:hover {
              background: $page-hover;
              color: $page-hover-font;
            }
          }
        }

        &.active {
          > .page-link {
            background: $page-active;
            color: $page-active-font;
          }
        }

        &:hover {
          > .page-link {
            background: $page-hover;
            color: $page-hover-font;
          }
        }

        &.disabled,
        &.disabled:hover {
          > .page-link {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

// Build
// Base
@include kt-datatables-base();

// Skin
@include kt-datatables-skin();
