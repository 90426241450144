//
// Layout Config
// Here you can set the demo level SASS variables and override the global variables for this demo.
// Note: To override the global variables in the demo level use the variables without "!default". More info:  https://sass-lang.com/documentation/variables
//

// State colors
$kt-state-colors: (
  // Metronic states
    brand:
    (
      base: #03DAC5,
      inverse: #FFFAFA
    ),
  light: (
    base: #FFFAFA,
    inverse: #282a3c
  ),
  dark: (
    base: #282a3c,
    inverse: #FFFAFA
  ),
    primary: (
      base: #03DAC5,
      inverse: #FFFAFA
    ),
  success: (
    base: #1dc9b7,
    inverse: #FFFAFA
  ),
  info: (
    base: #5578eb,
    inverse: #FFFAFA
  ),
  warning: (
    base: #ffb822,
    inverse: #111111
  ),
  danger: (
    base: #fd397a,
    inverse: #FFFAFA
  )
);

// Layout
$kt-aside-default-width: 245px;
$kt-aside-offcanvas-width: 255px;

// Page padding
$kt-page-padding: (
  desktop: 0px,
  mobile: 0px
);

// Page container
$kt-page-container-width: 1380px; //  fixed page width
$kt-page-fluid-container-padding: 60px; // fluid page padding

// Body
$kt-page-body-bg-color: #fff; // page body bg color
