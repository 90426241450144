//
// Bootstrap Datetimepicker
//

// Base
.datepicker {
  width: 265px;
  padding: 10px;

  @include kt-not-rounded {
    border-radius: 0;
  }

  &.datepicker-orient-top {
    margin-top: 8px;
  }

  table {
    width: 100%;
  }

  td,
  th {
    font-weight: regular;
    width: 35px;
    height: 35px;

    @include kt-rounded {
      border-radius: 3px;
    }

    @include kt-not-rounded {
      border-radius: 0;
    }
  }

  thead {
    th {
      color: kt-base-color(label, 2);

      &.prev,
      &.datepicker-switch,
      &.next {
        font-weight: 500;
        color: kt-base-color(label, 2);

        i {
          font-size: 1.2rem;
          color: kt-base-color(label, 2);

          &:before {
            line-height: 0;
            vertical-align: middle;
          }
        }

        &:hover {
          background: kt-base-color(grey, 1) !important;
        }
      }

      &.dow {
        color: kt-base-color(label, 3);
        font-weight: 500;
      }
    }
  }

  tbody {
    tr > td {
      &.day {
        color: kt-base-color(label, 3);

        &:hover {
          background: kt-base-color(grey, 2);
          color: kt-base-color(label, 3);
        }

        &.old {
          color: kt-base-color(label, 2);
        }

        &.new {
          color: kt-base-color(label, 3);
        }

        &.selected,
        &.selected:hover,
        &.active,
        &.active:hover {
          background: kt-state-color(primary, base);
          color: kt-state-color(primary, inverse);
        }

        &.today {
          position: relative;
          background: rgba(kt-state-color(brand, base), 0.7) !important;
          color: kt-state-color(brand, inverse) !important;

          &:before {
            content: "";
            display: inline-block;
            border: solid transparent;
            border-width: 0 0 7px 7px;
            border-bottom-color: #FFFAFA;
            border-top-color: kt-base-color(grey, 2);
            position: absolute;
            bottom: 4px;
            right: 4px;
          }
        }

        &.range {
          background: kt-base-color(grey, 1);
        }
      }

      span.year,
      span.hour,
      span.minute,
      span.month {
        color: kt-base-color(label, 3);

        &:hover {
          background: kt-base-color(grey, 1);
        }

        &.focused,
        &.focused:hover,
        &.active:hover,
        &.active.focused:hover,
        &.active {
          background: kt-state-color(primary, base);
          color: kt-state-color(brand, inverse);
        }
      }
    }
  }

  tfoot {
    tr > th {
      &.today,
      &.clear {
        @include kt-rounded {
          border-radius: 3px;
        }
        font-weight: 500;

        &:hover {
          background: kt-base-color(grey, 2);
        }
      }
    }
  }

  &.datepicker-inline {
    border: 1px solid kt-base-color(grey, 2);
  }
}

.input-daterange {
  .input-group-addon {
    min-width: 44px;
  }

  input {
    @include kt-not-rounded {
      border-radius: 0 !important;
    }
    text-align: left;
  }
}
