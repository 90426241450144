//
// Modal
//

// Base
.modal {
  .modal-content {
    @include kt-rounded {
      border-radius: $kt-border-radius;
    }

    .modal-header {
      .modal-title {
        font-weight: 500;
        font-size: 1.3rem;
        color: kt-base-color(label, 4);

        small {
          font-weight: 400;
          font-size: 0.9rem;
          color: kt-base-color(label, 2);
        }
      }

      .close {
        outline: none !important;
        color: kt-base-color(label, 2);

        @include kt-close-icon();
        @include kt-hover-transition();

        &:before {
          font-size: 1.3rem;
        }

        span {
          display: none;
        }

        &:hover {
          color: kt-brand-color() ;
        }
      }
    }
  }

  // Stick to bottom right
  &.modal-sticky-bottom-right {
    $width-default: 500px;
    $width-lg: 600px;

    padding: 0 !important;
    max-width: $width-default;

    &.modal-sticky-lg {
      max-width: $width-lg;
    }

    height: auto;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 25px;
    right: 25px;
    margin: 0;
    box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);

    @include kt-rounded {
      border-radius: $kt-border-radius;
    }

    @include kt-tablet-and-mobile {
      bottom: 10px;
      right: 10px;
    }

    @include kt-mobile {
      max-width: 90%;
    }

    .modal-dialog {
      position: static;
      max-width: $width-default;
      width: auto;
      margin: 0;

      @include kt-rounded {
        border-radius: $kt-border-radius;
      }

      .modal-content {
        border: 0;
        @include kt-rounded {
          border-radius: $kt-border-radius;
        }
      }
    }

    &.modal-sticky-lg {
      .modal-dialog {
        max-width: $width-lg;
      }
    }
  }
}

.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

.modal-body {
  &.modal-body-fit {
    padding: 0;
  }
  &.modal-body-x-fit {
    padding-left: 0;
    padding-right: 0;
  }
  &.modal-body-y-fit {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include kt-media-above(xl) {
  .modal-dialog.modal-xl {
    max-width: kt-media-breakpoint(xl) - 100px;
  }
}
